import { ApolloError, gql, useQuery } from '@apollo/client';
import { call } from '@everlutionsk/helpers';
import { Button, Pagination, TableProps } from '@everlutionsk/ui';
import { Table } from '../../components/Table';
import { useFlashMutation, useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { SubmitButton, createFormSpec } from '@everlutionsk/ui-formik';
import { useNavigate } from '@everlutionsk/ui-router';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
  Select as MuiSelect,
  MenuItem
} from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { routing } from '../../Routes';
import { ContentHeader } from '../../components/ContentHeader';
import { CustomChip } from '../../components/CustomChip';
import { SearchBar } from '../../components/SearchBar';
import { TableArrowForward } from '../../components/TableArrowForward';
import { Tabs } from '../../components/Tabs';
import { UserManyQuery, UserOrderBy } from '../../graphql/types';
import { prettifyStatus } from '../../helpers';
import { ToUserOneNameLink } from '../Common/ToUserOneNameLink';
import { MultiAutocompleteField } from '../components/MultiAutocompleteField';
import { useIdentity } from '../../components/hooks/useIdentity';

export function UsersList() {
  const identity = useIdentity();
  const { state } = useLocation();
  const navigate = useNavigate();
  if (identity == null) throw Error('Access Denied! Missing valid identity.');

  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [status, setStatusTab] = React.useState<Status>('active');

  useEffect(() => {
    if (state && typeof state === 'object' && 'status' in state && state.status === 'requested') {
      navigate('/users', { state: null });
      setStatusTab('requested');
    }
  }, [state]);

  type Pages = 'portal' | 'standardisation';
  const [selected, setSelected] = useState<Pages>('portal');

  const [orderBy, setOrderBy] = useState<UserOrderBy>(UserOrderBy.fullNameAsc);

  const { data, pagination, error } = useOffsetPaginatedQuery(userManyQuery, {
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset },
        term: searchTerm,
        status: statusPicker({ status, state: state as Object }),
        hasRequestedPosition: status === 'positionRequest' ? true : undefined,
        requestedChanges: status === 'dataChangeRequest' ? true : undefined,
        standardisation: selected === 'standardisation' ? true : undefined
      },
      orderBy
    })
  });

  return (
    <>
      <ContentHeader
        title="Zamestanci"
        breadcrumbs={[{ title: 'Domov' }, { title: 'Zamestnanci' }]}
      />
      <Box mb={3}>
        <Grid container>
          <Grid item xs={12} md={4}>
            {(identity.role === 'superAdmin' || identity.role === 'admin') && (
              <MuiSelect
                style={{ padding: '5px' }}
                value={selected}
                defaultValue="portal"
                onChange={event => setSelected(event.target.value as Pages)}
              >
                {[
                  { label: 'Portál', value: 'portal' },
                  { label: 'Štandardizácia', value: 'standardisation' }
                ].map((item, id) => (
                  <MenuItem key={id} value={item.value}>
                    <Typography variant="h3">{item.label}</Typography>
                  </MenuItem>
                ))}
              </MuiSelect>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <SearchBar
              buttonTitle="+ Pridať zamestnanca"
              withButton={
                identity.role === 'superAdmin' ||
                identity.role === 'admin' ||
                identity.role === 'owner'
              }
              navigateTo={routing.users.add}
              onSearch={value => setSearchTerm(value)}
            />
          </Grid>
        </Grid>
      </Box>
      <Tabs
        onChange={setStatusTab}
        tabs={[
          {
            label: 'Aktívni',
            value: 'active',
            node: (
              <UserTable
                orderBy={orderBy}
                onOrderBy={setOrderBy}
                data={{
                  items: data?.userMany.items,
                  pagination,
                  error
                }}
              />
            )
          },
          {
            label: 'Neaktívni',
            value: 'inactive',
            node: (
              <UserTable
                orderBy={orderBy}
                onOrderBy={setOrderBy}
                data={{
                  items: data?.userMany.items,
                  pagination,
                  error
                }}
              />
            )
          },
          ['admin', 'superAdmin'].includes(identity.role) && {
            label: 'Žiadosti (nový používateľ)',
            value: 'requested',
            node: (
              <UserTable
                orderBy={orderBy}
                onOrderBy={setOrderBy}
                data={{
                  items: data?.userMany.items,
                  pagination,
                  error
                }}
              />
            )
          },
          ['admin', 'superAdmin'].includes(identity.role) && {
            label: 'Žiadosti (zmena pozície)',
            value: 'positionRequest',
            node: (
              <UserPositionRequestTable
                data={{
                  items: data?.userMany.items,
                  pagination,
                  error
                }}
              />
            )
          },
          ['admin', 'superAdmin', 'owner'].includes(identity.role) && {
            label: 'Žiadosti (zmena údajov)',
            value: 'dataChangeRequest',
            node: <UserChangeDataTable data={{ items: data?.userMany.items, error, pagination }} />
          }
        ]}
        value={status}
      />
    </>
  );
}

type Status =
  | 'active'
  | 'inactive'
  | 'deleted'
  | 'requested'
  | 'positionRequest'
  | 'dataChangeRequest';

function statusPicker({ status, state }: { status: Status; state: object | null }) {
  if (status === 'positionRequest') return 'active';
  if (status === 'dataChangeRequest') return 'active';
  if (state && 'status' in state && state.status === 'requested') return 'requested';

  return status;
}

const userManyQuery = gql<UserManyGQL>`
  query UserMany($input: UserFilterInput, $orderBy: UserOrderBy) {
    userMany(input: $input, orderBy: $orderBy) {
      total
      items {
        id
        fullName
        createdAt
        organisation {
          id
          name
          district
          region
          city
        }
        position {
          id
          name
        }
        requestedPosition {
          id
          name
        }
        requestedChanges {
          firstName
          lastName
          title
          phoneNumber
        }
        status
      }
    }
  }
`;

function UserTable({
  data,
  orderBy,
  onOrderBy
}: {
  data: {
    items: UserManyQuery['userMany']['items'] | undefined;
    pagination: Pagination;
    error: ApolloError | undefined;
  };
  orderBy: UserOrderBy;
  onOrderBy: (orderBy: UserOrderBy) => void;
}) {
  return (
    <Table
      pagination={data.pagination}
      items={data.items}
      error={data.error}
      sort={{ current: orderBy, onChange: onOrderBy }}
      columns={[
        {
          label: 'Meno',
          render: item => ToUserOneNameLink(item),
          sort: {
            asc: UserOrderBy.fullNameAsc,
            desc: UserOrderBy.fullNameDesc,
            default: UserOrderBy.fullNameAsc
          }
        },
        {
          label: 'Pozícia',
          render: item => item.position.map(item => item.name).join(', ')
        },
        {
          label: 'Organizácia',
          render: item =>
            item.organisation
              ? `${item.organisation.name} ${item.organisation.district ?? ''} ${
                  item.organisation.region ?? ''
                }`
              : '-',
          sort: {
            asc: UserOrderBy.organisationAsc,
            desc: UserOrderBy.organisationDesc,
            default: UserOrderBy.organisationAsc
          }
        },
        {
          label: 'Mesto',
          render: item => (item.organisation ? item.organisation.city : '-')
        },
        {
          label: 'Status',
          render: ({ status }) => (
            <CustomChip
              color={call(() => {
                if (status === 'rejected') return 'warning';
                if (status === 'onHold') return 'primary';

                return 'success';
              })}
              label={prettifyStatus(status)}
            />
          ),
          sort: {
            asc: UserOrderBy.statusAsc,
            desc: UserOrderBy.statusDesc,
            default: UserOrderBy.statusAsc
          }
        },
        {
          label: 'Dátum',
          render: ({ createdAt }) => (createdAt ? format(new Date(createdAt), 'dd.MM.yyyy') : '-'),
          sort: {
            asc: UserOrderBy.createdAtAsc,
            desc: UserOrderBy.createdAtDesc,
            default: UserOrderBy.createdAtAsc
          }
        },
        {
          label: '',
          render: ({ id }) => <TableArrowForward to={routing.user.profile(id)} />
        }
      ]}
    />
  );
}

function UserChangeDataTable({
  data
}: {
  data: {
    items: UserManyQuery['userMany']['items'] | undefined;
    pagination: Pagination;
    error: ApolloError | undefined;
  };
}) {
  return (
    <Table
      pagination={data.pagination}
      items={data.items}
      error={data.error}
      columns={[
        {
          label: 'Meno',
          render: item => item.fullName
        },
        {
          label: 'Pozícia',
          render: item => item.position.map(item => item.name).join(', ')
        },
        {
          label: 'Organizácia',
          render: item =>
            item.organisation
              ? `${item.organisation.name} ${item.organisation.district ?? ''} ${
                  item.organisation.region ?? ''
                }`
              : '-'
        },
        {
          label: 'Mesto',
          render: item => (item.organisation ? item.organisation.city : '-')
        },
        {
          label: 'Status',
          render: ({ status }) => <CustomChip color="success" label={prettifyStatus(status)} />
        },
        {
          label: '',
          render: ({ id }) => <TableArrowForward to={routing.user.changeData(id)} />
        }
      ]}
    />
  );
}

interface DialogProps {
  open: boolean;
  data?: {
    positions: Array<{ id: string; name: string }>;
    currentPositions: Array<{ id: string; name: string }>;
    userId: string;
  };
}

function UserPositionRequestTable({ data }: { data: Partial<TableProps> }) {
  const [dialogProps, setDialogProps] = useState<DialogProps>({
    open: false,
    data: undefined
  });
  return (
    <>
      <Table
        pagination={data.pagination}
        items={data.items}
        error={data.error}
        columns={[
          {
            label: 'Meno',
            render: item => ToUserOneNameLink(item)
          },
          {
            label: 'Aktuálne pozície',
            render: item => item.position.map(item => item.name).join(', ')
          },
          {
            label: 'Nové pozície',
            render: item => item.requestedPosition.map(item => item.name).join(', ')
          },
          {
            label: '',
            render: item => (
              <Link
                variant="button"
                onClick={() =>
                  setDialogProps({
                    open: true,
                    data: {
                      positions: item.requestedPosition,
                      currentPositions: item.position,
                      userId: item.id
                    }
                  })
                }
              >
                upraviť
              </Link>
            )
          }
        ]}
      />
      <AcceptPositionRequestDialog
        dialogProps={dialogProps}
        onClose={() => setDialogProps({ open: false, data: undefined })}
      />
    </>
  );
}

function AcceptPositionRequestDialog({
  dialogProps,
  onClose
}: {
  dialogProps: DialogProps;
  onClose: () => void;
}) {
  const { data: groupOptions } = useQuery(optionQuery, { skip: !dialogProps.open });

  const formSpec = useMemo(() => {
    return createFormSpec({ position: yup.array().required('Name is required field.') });
  }, []);

  const [update] = useFlashMutation(acceptPositionMutation, {
    successMsg: 'Pozícia bola uspešne zmenená',
    errorMsg: 'Nie je možné zmeniť pozíciu'
  });

  const [reject] = useFlashMutation(rejectPositionMutation, {
    successMsg: 'Zmena pozícií bola uspešne zamietnutá',
    errorMsg: 'Nie je možné zamietnuť pozíciu'
  });

  const currentIds = dialogProps.data?.currentPositions.map(({ id }) => id) ?? [];
  const requestedIds = dialogProps.data?.positions.map(({ id }) => id) ?? [];

  const currentRemoved = currentIds.filter(id => !requestedIds.includes(id));
  const requestedAdded = requestedIds.filter(id => !currentIds.includes(id));

  return (
    <Dialog fullWidth open={dialogProps.open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Zmena pozície</DialogTitle>
      <DialogContent>
        {dialogProps.data && dialogProps.data.currentPositions.length > 0 && (
          <>
            <Typography variant="body1">
              <b>Aktuálne pozície:</b>{' '}
            </Typography>
            {dialogProps.data?.currentPositions.map(({ id, name }, index) => (
              <Box key={id} display="inline">
                <Typography display="inline">{index !== 0 ? ', ' : ' '}</Typography>
                <Typography
                  display="inline"
                  color={currentRemoved.includes(id) ? 'error' : undefined}
                >
                  {name}
                </Typography>
              </Box>
            ))}
          </>
        )}
      </DialogContent>
      <Formik
        enableReinitialize
        {...formSpec({
          position: dialogProps.data
            ? dialogProps.data.positions.map(item => ({ label: item.name, value: item.id }))
            : []
        })}
        onSubmit={async ({ position }) => {
          if (dialogProps.data == null) return;

          await update({
            variables: {
              positionIds: position.map(item => item.value),
              userId: dialogProps.data.userId
            }
          });
          onClose();
        }}
      >
        <Form>
          <DialogContent>
            <MultiAutocompleteField
              label="Pozícia*"
              name="position"
              options={groupOptions?.positionGroups.map(item => ({
                value: item.value,
                label: item.label
              }))}
              coloredValues={requestedAdded}
            />
          </DialogContent>

          <Box mb={2}>
            <DialogActions
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                margin: '0px 20px 0px 20px'
              }}
            >
              <SubmitButton>Schváliť</SubmitButton>
              <Button
                style={{ marginLeft: '10px' }}
                variant="contained"
                color="secondary"
                onClick={async () => {
                  if (dialogProps.data == null) return;
                  await reject({ variables: { userId: dialogProps.data.userId } });
                  onClose();
                }}
              >
                Zamietnuť
              </Button>
            </DialogActions>
          </Box>
        </Form>
      </Formik>
    </Dialog>
  );
}

const optionQuery = gql<PositionGroupAcceptOptionsGQL>`
  query PositionGroupAcceptOptions {
    positionGroups {
      label
      value
    }
  }
`;

const acceptPositionMutation = gql<AcceptPositionChangeGQL>`
  mutation AcceptPositionChange($userId: ID!, $positionIds: [String!]!) {
    acceptPositionChange(userId: $userId, positionIds: $positionIds) {
      id
      fullName
      organisation {
        id
        name
      }
      position {
        id
        name
      }
      requestedPosition {
        id
        name
      }
      status
    }
  }
`;

const rejectPositionMutation = gql<RejectPositionChangeGQL>`
  mutation RejectPositionChange($userId: ID!) {
    rejectPositionChange(userId: $userId) {
      id
      fullName
      organisation {
        id
        name
      }
      position {
        id
        name
      }
      requestedPosition {
        id
        name
      }
      status
    }
  }
`;
