import { gql, useQuery } from '@apollo/client';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { CheckboxField, createFormSpec, RadioField, SubmitButton } from '@everlutionsk/ui-formik';
import { useParams } from '@everlutionsk/ui-router';
import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';

export function StandardisationSettings() {
  const { id } = useParams(['id']);
  const { data } = useQuery(accessQuery, { variables: { userId: id } });
  const { data: accessOptions } = useQuery(rolesManyQuery);

  const [editAccess] = useFlashMutation(editAccessMutation, {
    successMsg: 'Rola pre štandardizáciu bola úspešne upravená.',
    errorMsg: 'Rolu pre štandardizáciu sa nepodarilo upraviť.'
  });

  const formSpec = useMemo(() => {
    return createFormSpec({
      access: yup.boolean().required(),
      role: yup.string().when('access', {
        is: true,
        then: schema =>
          schema
            .oneOf(
              accessOptions
                ? accessOptions.userStandardisationAccessMany.map(({ value }) => value)
                : []
            )
            .required('Pre prístup k štandardizácii je potrebné zvoliť rolu.'),
        otherwise: schema => schema.nullable()
      })
    });
  }, [accessOptions]);

  if (data == null || accessOptions == null) {
    return (
      <>
        {[...new Array(3)].map((_, index) => (
          <Grid key={index} container>
            <Grid item xs={12}>
              <Skeleton height={40} />
            </Grid>
          </Grid>
        ))}
      </>
    );
  }

  return (
    <Card>
      <Box mb={3} p={3}>
        <Formik
          enableReinitialize
          {...formSpec({
            access: data.userStandardisationAccessOne.role != null,
            role: data?.userStandardisationAccessOne.role ?? undefined
          })}
          onSubmit={({ access, role }) =>
            editAccess({
              variables: {
                input: {
                  userId: id,
                  role: access ? role : undefined
                }
              }
            })
          }
        >
          {({ values }) => {
            return (
              <Form>
                <CheckboxField name="access" label="prístup k štandardizácii" />
                {values.access && (
                  <Box>
                    <Typography variant="body1">Vyberte rolu:</Typography>
                    <RadioField
                      name="role"
                      options={accessOptions.userStandardisationAccessMany ?? []}
                    />
                  </Box>
                )}
                <Box>
                  <SubmitButton>Uložiť</SubmitButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Card>
  );
}

const accessQuery = gql<StandardisationAccessOneQueryGQL>`
  query StandardisationAccessOneQuery($userId: ID!) {
    userStandardisationAccessOne(userId: $userId) {
      role
    }
  }
`;

const rolesManyQuery = gql<StandardisationAccessManyQueryGQL>`
  query StandardisationAccessManyQuery {
    userStandardisationAccessMany {
      label
      value
    }
  }
`;

const editAccessMutation = gql<StandardisationAccessEditGQL>`
  mutation StandardisationAccessEdit($input: StandardisationAccessInput!) {
    userStandardisationAccessEdit(input: $input) {
      id
    }
  }
`;
