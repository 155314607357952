import { Table as UiTable, TableProps as UiTableProps } from '@everlutionsk/ui';
import { TablePagination } from '@mui/material';
import React from 'react';

interface TableProps extends UiTableProps {
  pagination?: {
    /**
     * Total number of items.
     */
    readonly count: number;
    /**
     * Page number.
     *
     * Starts from 0.
     */
    readonly page: number;
    readonly rowsPerPage: number;
    readonly onPageChange: (
      event: React.MouseEvent<HTMLButtonElement> | null,
      page: number
    ) => void;
    readonly onRowsPerPageChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    readonly rowsPerPageOptions:
      | number[]
      | {
          value: number;
          label: string;
        }[];
    readonly nextIconButtonProps?: {
      disabled: boolean;
    };
    readonly backIconButtonProps?: {
      disabled: boolean;
    };
  };
}

export function Table({ pagination, ...props }: TableProps) {
  return (
    <>
      <UiTable {...props} />
      {pagination && (
        <TablePagination
          component="div"
          {...pagination}
          labelRowsPerPage="Počet riadkov na stránku"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} z ${count !== -1 ? count : `viac ako ${to}`}`
          }
        />
      )}
    </>
  );
}
