declare let process: { env: { [key: string]: any } };

export const environment = {
  isProd: process.env.NODE_ENV === 'production',
  apiUrl: process.env.API_ENDPOINT,
  stage: process.env.STAGE,

  frontendDomain: process.env.FRONTEND_DOMAIN,
  standardisationUrl: process.env.STANDARDISATION_URL
};
