import { ApolloClient, ApolloProvider, gql, useQuery } from '@apollo/client';
import { UiProvider, withScrollRestoration } from '@everlutionsk/ui';
import { Navigate, Redirect, Route, Router, Routes, useNavigate } from '@everlutionsk/ui-router';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { routingPatterns } from '../Routes';
import {
  AuthenticatedRoute,
  SafeRoute,
  StandardisationRoute
} from '../components/AuthenticatedRoute';
import { Header } from '../components/Header';
import { Logo } from '../components/Logo';
import { Role, StandardisationRole } from '../graphql/types';
import { useTheme } from '../theme';
import { Dashboard } from './Dashboard';
import { Diagnostic } from './Diagnostic';
import { Diagnostics } from './Diagnostics';
import { AddGroup, EditGroup, EditPositionGroup, Groups, PositionGroups } from './Groups';
import { Organisation } from './Organisation';
import { OrganisationRequest } from './OrganisationRequest';
import { Organisations } from './Organisations';
import { Student } from './Student';
import { Students } from './Students';
import { TestResults } from './TestResults';
import { Users } from './Users';
import { User } from './Users/User';
import { MyProfile } from './Users/User/MyProfile';
import { VideoDetail, VideoList } from './Videos';
import { adminNavigationLayer } from './adminNavigationLayer';
import { analystNavigationLayer } from './analystNavigationLayer';
import { FooterContent } from './components/FooterContenet';
import {
  ownerPsychologistNavigationLayer,
  psychologistNavigationLayer
} from './psychologistNavigationLayer';
import { Identity, useIdentity } from '../components/hooks/useIdentity';
import {
  NavigationLayer,
  NavigationPanelProvider,
  SidebarNavigation
  // SidebarNavigation,
} from '@everlutionsk/ui-admin';
import { Box, styled } from '@mui/material';
import { environment } from '../environment';
import { ErrorPageProvider } from './components/errorPageProvider';
import { ExternalRedirect } from './externalRedirect';
import { Assignment } from '@mui/icons-material';

interface Props {
  readonly apollo: ApolloClient<any>;
}

export function App({ apollo }: Props) {
  const theme = useTheme({ isProd: environment.isProd });

  return (
    <UiProvider theme={theme} flashMessages historyState>
      <ApolloProvider client={apollo}>
        <Router>
          <ErrorPageProvider>
            <Routes>
              {/* <Route path={routingPatterns.auth.pattern} element={<Auth />} /> */}
              <AuthenticatedRoute path={'*'} element={<Pages />} />
            </Routes>
          </ErrorPageProvider>
        </Router>
      </ApolloProvider>
    </UiProvider>
  );
}

function Pages() {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const identity = useIdentity();

  useOwnerNavigate(identity);

  const navigationLayer =
    identity?.status === 'requested'
      ? { title: '', items: [] }
      : getNavigationLayer({
          role: identity?.role,
          standardisationRole: identity?.standardisationRole
        });

  return (
    <NavigationPanelProvider root={navigationLayer}>
      <Page>
        <Box
          display="flex"
          sx={{
            '& .MuiListItemButton-root': { paddingLeft: '10px' }
          }}
        >
          <SidebarNavigation
            logo={<Logo />}
            open={isMobileNavOpen}
            onOpenRequest={() => setMobileNavOpen(true)}
            onCloseRequest={() => setMobileNavOpen(false)}
          />
        </Box>

        <Main>
          <Header onMobileNavOpen={() => setMobileNavOpen(true)} />
          <ContentWrapper>
            <Content>
              <Routes>
                <SafeRoute
                  allowedRoles={Object.values(Role)}
                  path="organisation-request"
                  element={<OrganisationRequest />}
                />
                <SafeRoute
                  allowedRoles={Object.values(Role)}
                  path={routingPatterns.dashboard}
                  element={<Dashboard />}
                />

                <SafeRoute
                  allowedRoles={['superAdmin', 'admin', 'psychologist', 'owner']}
                  path={routingPatterns.students.pattern}
                  element={<Students />}
                />

                <SafeRoute
                  allowedRoles={['superAdmin', 'admin', 'psychologist', 'owner']}
                  path={routingPatterns.student.pattern}
                  element={<Student />}
                />

                <SafeRoute
                  allowedRoles={['superAdmin', 'admin', 'psychologist', 'owner']}
                  path={routingPatterns.diagnostics.pattern}
                  element={<Diagnostics />}
                />

                <SafeRoute
                  allowedRoles={['superAdmin', 'admin', 'psychologist', 'owner']}
                  path={routingPatterns.diagnostic.pattern}
                  element={<Diagnostic />}
                />

                <SafeRoute
                  allowedRoles={['analyst']}
                  path={routingPatterns.testResults.pattern}
                  element={<TestResults />}
                />

                <SafeRoute
                  allowedRoles={['superAdmin', 'admin', 'psychologist', 'owner']}
                  path={routingPatterns.users.pattern}
                  element={<Users />}
                />

                <SafeRoute
                  allowedRoles={['superAdmin', 'admin', 'psychologist', 'owner']}
                  path={routingPatterns.user.pattern}
                  element={<User />}
                />
                <SafeRoute
                  allowedRoles={['psychologist', 'owner', 'admin', 'superAdmin']}
                  path="my-profile/*"
                  element={<MyProfile />}
                />

                <SafeRoute
                  allowedRoles={['superAdmin', 'admin']}
                  path={routingPatterns.organisations.pattern}
                  element={<Organisations />}
                />

                <SafeRoute
                  allowedRoles={['owner']}
                  path="organisation"
                  element={<Organisation />}
                />

                <SafeRoute
                  allowedRoles={Object.values(Role)}
                  path="/video-list/*"
                  element={
                    <Routes>
                      <Route path="" element={<VideoList />} />
                      <Route path=":id" element={<VideoDetail />} />
                    </Routes>
                  }
                />

                <SafeRoute
                  allowedRoles={['superAdmin', 'admin']}
                  path="/groups/*"
                  element={
                    <Routes>
                      <Route path="" element={<Groups />} />
                      <Route path="/add" element={<AddGroup />} />
                      <Route path="/edit/:id" element={<EditGroup />} />
                    </Routes>
                  }
                />
                <SafeRoute
                  allowedRoles={['superAdmin', 'admin']}
                  path="/positions/*"
                  element={
                    <Routes>
                      <Route path="" element={<PositionGroups />} />
                      <Route path="/edit/:id" element={<EditPositionGroup />} />
                    </Routes>
                  }
                />
                <StandardisationRoute
                  path={'/standardisation'}
                  element={<ExternalRedirect url={environment.standardisationUrl} />}
                />
              </Routes>
            </Content>
          </ContentWrapper>

          <Content>
            <FooterContent />
          </Content>
        </Main>
      </Page>
    </NavigationPanelProvider>
  );
}

function useOwnerNavigate(identity: Identity | null) {
  if (identity == null) throw Error('Access Denied! Missing valid identity.');

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { data } = useQuery(query, {
    variables: { id: identity.id },
    skip: ['admin', 'superAdmin', 'analyst'].includes(identity.role)
  });

  const isActive = data?.userOne.status === 'active';
  const organisationPath = pathname.includes('organisation-request');
  const profilePath = pathname.includes('profile');

  useEffect(() => {
    if (data == null) return;
    if (isActive) {
      if (data.userOne.position.length === 0) {
        navigate(`/user/${data.userOne.id}/profile`);
      }

      return;
    }

    return navigate('/organisation-request');
  }, [data, organisationPath, profilePath, isActive]);
}

const query = gql<UserOneRedirectGQL>`
  query UserOneRedirect($id: ID!) {
    userOne(id: $id) {
      id
      fullName
      role
      status
      organisation {
        id
        name
      }
      position {
        id
        name
      }
    }
  }
`;

function getNavigationLayer({
  role,
  standardisationRole
}: {
  role?: Role;
  standardisationRole: StandardisationRole | undefined | null;
}) {
  if (role === Role.admin || role === Role.superAdmin) {
    return toStandardisationPath({
      navigation: adminNavigationLayer,
      standardisationRole,
      isAdmin: true
    });
  }

  if (role === Role.psychologist) {
    return toStandardisationPath({ navigation: psychologistNavigationLayer, standardisationRole });
  }

  if (role === Role.owner) {
    return toStandardisationPath({
      navigation: ownerPsychologistNavigationLayer,
      standardisationRole
    });
  }

  return toStandardisationPath({ navigation: analystNavigationLayer, standardisationRole });
}

function toStandardisationPath({
  navigation,
  standardisationRole,
  isAdmin
}: {
  navigation: any;
  standardisationRole: StandardisationRole | undefined | null;
  isAdmin?: boolean;
}) {
  if (isAdmin || standardisationRole != null)
    if (navigation.items.filter(({ path }) => path === '/standardisation').length == 0)
      navigation.items.push({
        title: 'Štandardizácia',
        icon: Assignment,
        path: '/standardisation'
      });

  return navigation;
}

const Page = styled('div')`
  overflow: hidden;
  display: flex;
  height: auto;
  min-height: 100vh;
`;

const Main = styled('div')`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => {
    // console.log(theme.palette.text);
    // TODO: bad color?
    // return theme.palette.text.disabled;
    return 'rgb(247, 247, 247);';
  }};
`;

const ContentWrapper = withScrollRestoration(
  styled('div')({
    position: 'relative',
    flex: '1 1 auto',
    '@media not print': {
      overflowY: 'auto',
      minHeight: '90vh'
    }
  })
);

const Content = styled('div')`
  max-width: 1024px;
  padding: 24px;
  margin-left: auto;
  margin-right: auto;
`;
