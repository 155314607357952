import { Centered } from '@everlutionsk/ui';
import { Typography } from '@mui/material';
import React from 'react';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { Role } from '../graphql/types';
import { useIdentity } from './hooks/useIdentity';

/**
 * Renders the given element when user is logged in with supported role.
 */
export function AuthenticatedRoute(props: RouteProps) {
  const identity = useIdentity();
  const signedIn = identity != null;

  // const element = signedIn ? props.element : <Forbidden />;
  if (signedIn) return <Route {...props} element={props.element} />;
  return <Forbidden />;
}

export function Forbidden() {
  const identity = useIdentity();

  if (identity == null) {
    // TODO:
    // const from = encodeURIComponent(window.location.pathname);
    // const to = `/auth/sign-in?from=${from}`;
    // const to = `/`;
    // return <Navigate to={to} />;
    return <></>;
  }

  // We renders 404 page when user's role is not what we expected.
  return (
    <Centered>
      <Typography variant="h3">Page not found!</Typography>
    </Centered>
  );
}

export function SafeRoute(props: RouteProps & { allowedRoles: Role[] }) {
  const identity = useIdentity();
  if (identity == null) return <Forbidden />;

  if (!props.allowedRoles.includes(identity.role)) return <Forbidden />;
  // if (props.allowedRoles.find(role => !identity.role.includes(role))) {
  //   return <Forbidden />;
  // }
  return <Route {...props} />;
}

export function StandardisationRoute(props: RouteProps) {
  const identity = useIdentity();
  if (identity == null) return <Forbidden />;

  if (!['superAdmin'].includes(identity.role) && identity.standardisationRole == null)
    return <Forbidden />;

  return <Route {...props} />;
}
