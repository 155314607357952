/* eslint-disable */

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// DO NOT EDIT THIS FILE!
//
// GENERATED BY @everlutionsk/graphql-types-generator package!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Void: void;
  DateTime: string;
};

export type SignInInput = {
  login: Scalars['String'];
  password: Scalars['String'];
};

export type RefreshIdentityInput = {
  refreshToken: Scalars['String'];
};

export type UserGroupManyInput = {
  pagination?: Maybe<PaginationInput>;
};

export type DiagnosticsIdentification202Input = {
  clientIdentifier: Scalars['String'];
  birthMonth: Scalars['Int'];
  birthYear: Scalars['Int'];
  gender: Gender;
  token: Scalars['String'];
  articleId: Scalars['String'];
  elementarySchoolClass: Scalars['Int'];
  postponementOfSchool: Scalars['Boolean'];
  repeatedYearOfSchool: Scalars['Boolean'];
  ratingSlovakLanguage: Scalars['Int'];
  ratingMathematics: Scalars['Int'];
  ratingForeignLanguage?: Maybe<Scalars['Int']>;
  ratingOfReading?: Maybe<ReaderRating>;
  motherEducation?: Maybe<Education>;
  fatherEducation?: Maybe<Education>;
};

export const Gender = {
  male: 'male',
  female: 'female'
} as const;

export type Gender = (typeof Gender)[keyof typeof Gender];
export const ReaderRating = {
  excellent: 'excellent',
  veryGood: 'veryGood',
  average: 'average',
  belowAverage: 'belowAverage',
  deeplyBelowAverage: 'deeplyBelowAverage'
} as const;

export type ReaderRating = (typeof ReaderRating)[keyof typeof ReaderRating];
export const Education = {
  basicIncomplete: 'basicIncomplete',
  basicUnskilled: 'basicUnskilled',
  basicSkilled: 'basicSkilled',
  highSchool: 'highSchool',
  university: 'university'
} as const;

export type Education = (typeof Education)[keyof typeof Education];
export type DiagnosticsIdentificationInput = {
  clientIdentifier: Scalars['String'];
  birthMonth: Scalars['Int'];
  birthYear: Scalars['Int'];
  gender: Gender;
  date: Scalars['Date'];
  token: Scalars['String'];
};

export type SubmitT8V1Input = {
  token: Scalars['String'];
  answersTable1: Array<T8Table1Answer>;
};

export type T8Table1Answer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
  answer?: Maybe<Scalars['String']>;
};

export type SubmitT8V2Input = {
  token: Scalars['String'];
  answersTable1: Array<T8Table1Answer>;
  answersTable2: Array<T8Table2Answer>;
};

export type T8Table2Answer = {
  id: Scalars['String'];
  match: Scalars['Boolean'];
  notMatch: Scalars['String'];
};

export type SubmitT21Input = {
  token: Scalars['String'];
  answers: Array<T21Answer>;
};

export type T21Answer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export const T41Route = {
  identificationChildrenA: 'identificationChildrenA',
  resultsChildrenA: 'resultsChildrenA',
  identificationAdultsA: 'identificationAdultsA',
  resultsAdultsA: 'resultsAdultsA',
  identificationChildrenB: 'identificationChildrenB',
  resultsChildrenB: 'resultsChildrenB',
  identificationAdultsB: 'identificationAdultsB',
  resultsAdultsB: 'resultsAdultsB'
} as const;

export type T41Route = (typeof T41Route)[keyof typeof T41Route];

export type SubmitT41Input = {
  token: Scalars['String'];
  duration: Scalars['Int'];
  version: T41DiagnosticVersion;
  grade?: Maybe<Scalars['Int']>;
};

export const T41DiagnosticVersion = {
  childrenA: 'childrenA',
  childrenB: 'childrenB',
  adultsA: 'adultsA',
  adultsB: 'adultsB'
} as const;

export type T41DiagnosticVersion = (typeof T41DiagnosticVersion)[keyof typeof T41DiagnosticVersion];

export type SubmitT184V1Input = {
  token: Scalars['String'];
  answersTable: Array<T184TableAnswer>;
  additionalText: Scalars['String'];
};

export type SubmitT184V2Input = {
  token: Scalars['String'];
  answersTable: Array<T184TableAnswer>;
  additionalText: Scalars['String'];
};

export type T184TableAnswer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export type SubmitT202Page2Input = {
  token: Scalars['String'];
  wayOfReading: Scalars['Int'];
  readingReproduction: Scalars['Int'];
  readingReproductionScale: Scalars['Int'];
  readingSense: Scalars['Int'];
  readingLocalizationOne: Scalars['String'];
  readingLocalizationTwo: Scalars['String'];
  readingLocalizationThree: Scalars['String'];
  readingLocalizationFour: Scalars['String'];
  readingLocalizationFive: Scalars['String'];
  readingLocalizationSix: Scalars['String'];
  readingLocalizationSeven: Scalars['String'];
  errorShapes: Array<Scalars['Int']>;
  durationEntry?: Maybe<Scalars['Int']>;
};

export type SubmitT202Page1Input = {
  token: Scalars['String'];
  articleId: Scalars['String'];
  firstMinuteWord: Scalars['Int'];
  secondMinuteWord: Scalars['Int'];
  thirdMinuteWord: Scalars['Int'];
  duration: Scalars['Int'];
  mistakes: Array<Scalars['Int']>;
};

export type SubmitT216Input = {
  token: Scalars['String'];
  answers: Array<T216Answer>;
};

export type T216Answer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export type DiagnosticsIdentification216Input = {
  token: Scalars['String'];
  clientIdentifier: Scalars['String'];
  birthMonth: Scalars['Int'];
  birthYear: Scalars['Int'];
  gender: Gender;
  ratingSlovakLanguage: Scalars['Int'];
  ratingMaths: Scalars['Int'];
  ratingTechnicalEducation?: Maybe<Scalars['Int']>;
  ratingArtEducation?: Maybe<Scalars['Int']>;
};

export type SubmitT239Page6Input = {
  token: Scalars['String'];
  answers: Array<T239Page6Value>;
};

export type T239Page6Value = {
  id: Scalars['String'];
  crossed: Scalars['Boolean'];
};

export type SubmitT239Page8Input = {
  token: Scalars['String'];
  table1: Array<T239Page8TableValue>;
  table2: Array<T239Page8TableValue>;
  conclusion: T239TextValue;
  answers2: Array<T239TextValue>;
};

export type T239Page8TableValue = {
  id: Scalars['String'];
  crossed: Scalars['Boolean'];
};

export type SubmitT239Page1Input = {
  token: Scalars['String'];
  differentiation: Array<T239ChoiceValue>;
  synthesis: Array<T239ChoiceValue>;
  analysis: Array<T239ChoiceValue>;
};

export type SubmitT239Page2Input = {
  token: Scalars['String'];
  dropdowns: Array<T239ChoiceValue>;
  textInputs: Array<T239TextValue>;
};

export type SubmitT239Page3Input = {
  token: Scalars['String'];
  plhs: Array<T239ChoiceValue>;
  phs: Array<T239ChoiceValue>;
  lhs: Array<T239ChoiceValue>;
};

export type SubmitT239Page4Input = {
  token: Scalars['String'];
  perception: Array<T239Page4PerceptionValue>;
  reproduction: Array<T239ChoiceValue>;
};

export type T239Page4PerceptionValue = {
  id: Scalars['String'];
  value: Scalars['Int'];
  answer: Scalars['String'];
};

export type SubmitT239Page5Input = {
  token: Scalars['String'];
  answers: Array<T239ChoiceValue>;
};

export type T239ChoiceValue = {
  id: Scalars['String'];
  value: Scalars['Int'];
};

export type T239TextValue = {
  id: Scalars['String'];
  value: Scalars['String'];
};

export type SubmitT252Input = {
  token: Scalars['String'];
  answers: Array<T252Answer>;
};

export type T252Answer = {
  id: Scalars['String'];
  choices: Array<T252AnswerChoice>;
};

export type T252AnswerChoice = {
  id: Scalars['String'];
  value: Scalars['Int'];
};

export type DiagnosticsIdentification184Input = {
  token: Scalars['String'];
  clientIdentifier: Scalars['String'];
  birthMonth: Scalars['Int'];
  birthYear: Scalars['Int'];
  gender: Gender;
  subject: Scalars['String'];
};

export type SubmitT309Page1Input = {
  token: Scalars['String'];
  answers: Array<T309Page1Answer>;
};

export type T309Page1Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitT309Page2Input = {
  token: Scalars['String'];
  answers: Array<T309Page2Answer>;
};

export type T309Page2Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitT309Page3Input = {
  token: Scalars['String'];
};

export type SubmitT309Page4Input = {
  token: Scalars['String'];
  answers: Array<T309Page4Answer>;
};

export type T309Page4Answer = {
  id: Scalars['String'];
  answer: Scalars['Int'];
};

export type SubmitT309Page5Input = {
  token: Scalars['String'];
  answers: Array<T309Page5Answer>;
};

export type T309Page5Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitT309Page6Input = {
  token: Scalars['String'];
  answers: Array<T309Page6Answer>;
};

export type T309Page6Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitT309Page7Input = {
  token: Scalars['String'];
  answers: Array<T309Page7Answer>;
};

export type T309Page7Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitT309Page8Input = {
  token: Scalars['String'];
  answers: Array<T309Page8Answer>;
};

export type T309Page8Answer = {
  id: Scalars['String'];
  answer: Scalars['Int'];
};

export type SubmitT309Page9Input = {
  token: Scalars['String'];
  answers: Array<T309Page9Answer>;
};

export type T309Page9Answer = {
  id: Scalars['String'];
  a: Scalars['String'];
  b: Scalars['String'];
};

export type SubmitT309Page10Input = {
  token: Scalars['String'];
  answers: Array<T309Page10Answer>;
};

export type T309Page10Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitOtprInput = {
  token: Scalars['String'];
  version: Scalars['String'];
  answers1: Array<OtprAnswer>;
  answers2: Array<OtprAnswer>;
  answers3: Array<OtprAnswer>;
  answers4: Array<OtprAnswer>;
  answers5: Array<OtprAnswer>;
  interrupted?: Maybe<Scalars['Boolean']>;
  interruptionReason?: Maybe<Scalars['String']>;
  ended?: Maybe<Scalars['Boolean']>;
  endingReason?: Maybe<Scalars['String']>;
  downgraded?: Maybe<Scalars['Boolean']>;
  communication?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type OtprAnswer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export type SubmitT95Input = {
  token: Scalars['String'];
  answers: Array<T95Answer>;
  sightDisability: Scalars['Boolean'];
  leftEye?: Maybe<Scalars['String']>;
  rightEye?: Maybe<Scalars['String']>;
  hearingDisability: Scalars['Boolean'];
  attention: T95Answer;
  ciq?: Maybe<Scalars['String']>;
  viq?: Maybe<Scalars['String']>;
  niq?: Maybe<Scalars['String']>;
  repetitions: Array<Scalars['Int']>;
  confabulations: Array<Scalars['Int']>;
  notes: Scalars['String'];
};

export type T95Answer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export type SubmitSmkzdInput = {
  token: Scalars['String'];
  answers: Array<SmkzdAnswer>;
};

export type SmkzdAnswer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export type PaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type StudentFilterInput = {
  term?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  status?: Maybe<StudentStatus>;
};

export const StudentStatus = {
  active: 'active',
  inactive: 'inactive'
} as const;

export type StudentStatus = (typeof StudentStatus)[keyof typeof StudentStatus];

export type AddStudentInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender: Gender;
  dateOfBirth: Scalars['String'];
  birthIdentificationNumber: Scalars['String'];
  school: Scalars['String'];
  line1: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  fullName: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
  language: Scalars['String'];
};

export type EditStudentInput = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  birthIdentificationNumber: Scalars['String'];
  school: Scalars['String'];
  line1: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  fullName: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  grade?: Maybe<Scalars['Int']>;
  postponement?: Maybe<Scalars['Int']>;
  repeatedGrade?: Maybe<Scalars['Int']>;
  ratingSlovakLanguage?: Maybe<Scalars['Int']>;
  ratingMaths?: Maybe<Scalars['Int']>;
  ratingForeignLanguage?: Maybe<Scalars['Int']>;
  ratingArtEducation?: Maybe<Scalars['Int']>;
  ratingTechnicalEducation?: Maybe<Scalars['Int']>;
  readingEvaluation?: Maybe<Scalars['Int']>;
  motherEducation?: Maybe<Scalars['Int']>;
  fatherEducation?: Maybe<Scalars['Int']>;
  otherData?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export type EditStudentDiagnosisInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type DiagnosticFilterInput = {
  term?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<Scalars['String']>;
};

export const DiagnosticForm = {
  individual: 'individual',
  remote: 'remote',
  group: 'group'
} as const;

export type DiagnosticForm = (typeof DiagnosticForm)[keyof typeof DiagnosticForm];
export type TestingInput = {
  code: Scalars['String'];
  studentId: Scalars['ID'];
  expiresAt?: Maybe<Scalars['String']>;
};

export type TestingFilterInput = {
  term?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<Scalars['String']>;
  groupTestingId?: Maybe<Scalars['String']>;
  status?: Maybe<TestingStatus>;
};

export const TestingStatus = {
  ready: 'ready',
  inProgress: 'inProgress',
  finished: 'finished',
  expired: 'expired',
  readonly: 'readonly'
} as const;

export type TestingStatus = (typeof TestingStatus)[keyof typeof TestingStatus];

export type NewGroupTestingInput = {
  code: Scalars['String'];
  groupName: Scalars['String'];
  expiresAt: Scalars['String'];
  studentIds: Array<Scalars['ID']>;
};

export type SelectedGroupTestingInput = {
  code: Scalars['String'];
  groupId: Scalars['String'];
  expiresAt: Scalars['String'];
};

export const UserStatus = {
  active: 'active',
  inactive: 'inactive',
  deleted: 'deleted',
  requested: 'requested',
  onHold: 'onHold',
  rejected: 'rejected'
} as const;

export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];
export const Role = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  psychologist: 'psychologist',
  analyst: 'analyst',
  owner: 'owner'
} as const;

export type Role = (typeof Role)[keyof typeof Role];
export type InviteInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organisationId?: Maybe<Scalars['String']>;
  position: Array<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Role;
};

export type AcceptInvitationInput = {
  password: Scalars['String'];
  email: Scalars['String'];
  token: Scalars['String'];
};

export type UserFilterInput = {
  term?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  withoutUserGroup?: Maybe<Scalars['Boolean']>;
  hasRequestedPosition?: Maybe<Scalars['Boolean']>;
  requestedChanges?: Maybe<Scalars['Boolean']>;
  standardisation?: Maybe<Scalars['Boolean']>;
};

export type EditUserInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['String']>;
  position?: Maybe<Array<Scalars['String']>>;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
};

export type RequestedUserChangesInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type DemoTestingInput = {
  code: Scalars['String'];
};

export type SendTestingInput = {
  to: Scalars['String'];
  from: Scalars['String'];
  message: Scalars['String'];
};

export type AddGroupInput = {
  name: Scalars['String'];
  studentIds: Array<Scalars['ID']>;
};

export type GroupFilterInput = {
  term?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<Scalars['String']>;
};

export type UpdateStudentByTokenInput = {
  token: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  birthMonth?: Maybe<Scalars['Int']>;
  birthYear?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  date?: Maybe<Scalars['Date']>;
  grade?: Maybe<Scalars['Int']>;
  education?: Maybe<Scalars['String']>;
  postponement?: Maybe<Scalars['Int']>;
  repeatedGrade?: Maybe<Scalars['Int']>;
  ratingSlovakLanguage?: Maybe<Scalars['Int']>;
  ratingMaths?: Maybe<Scalars['Int']>;
  ratingForeignLanguage?: Maybe<Scalars['Int']>;
  ratingArtEducation?: Maybe<Scalars['Int']>;
  ratingTechnicalEducation?: Maybe<Scalars['Int']>;
  readingEvaluation?: Maybe<Scalars['Int']>;
  motherEducation?: Maybe<Scalars['Int']>;
  fatherEducation?: Maybe<Scalars['Int']>;
  t184subject?: Maybe<Scalars['String']>;
};

export type CompleteResetPasswordInput = {
  email: Scalars['String'];
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ManualManyInput = {
  limit: Scalars['Int'];
  code: Scalars['String'];
};

export type AcceptOrganisationChangesInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
};

export type RequestedOrganisationChangesInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
};

export type AddOrganisationInput = {
  name: Scalars['String'];
  line1: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['String'];
  district: Scalars['String'];
};

export type OrganisationFilterInput = {
  term?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  status?: Maybe<OrganisationStatus>;
  requestedChanges?: Maybe<Scalars['Boolean']>;
  withoutUserGroup?: Maybe<Scalars['Boolean']>;
};

export type EditOrganisationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  status: OrganisationStatus;
  line1: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['String'];
  district: Scalars['String'];
};

export const OrganisationStatus = {
  enable: 'enable',
  disabled: 'disabled'
} as const;

export type OrganisationStatus = (typeof OrganisationStatus)[keyof typeof OrganisationStatus];

export type UserRequestCreateInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  position: Array<Scalars['String']>;
  phoneNumber: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Role;
  organisationId: Scalars['String'];
};

export type UserGroupInput = {
  name: Scalars['String'];
  organisationIds: Array<Scalars['String']>;
  userIds: Array<Scalars['String']>;
  videoIds: Array<Scalars['String']>;
  testIds: Array<Scalars['String']>;
};

export type PositionGroupInput = {
  id: Scalars['ID'];
  videoIds: Array<Scalars['String']>;
  testIds: Array<Scalars['String']>;
};

export const UserOrderBy = {
  fullNameAsc: 'fullNameAsc',
  fullNameDesc: 'fullNameDesc',
  createdAtAsc: 'createdAtAsc',
  createdAtDesc: 'createdAtDesc',
  organisationAsc: 'organisationAsc',
  organisationDesc: 'organisationDesc',
  statusAsc: 'statusAsc',
  statusDesc: 'statusDesc'
} as const;

export type UserOrderBy = (typeof UserOrderBy)[keyof typeof UserOrderBy];
export const OrganisationOrderBy = {
  nameAsc: 'nameAsc',
  nameDesc: 'nameDesc',
  regionAsc: 'regionAsc',
  regionDesc: 'regionDesc',
  districtAsc: 'districtAsc',
  districtDesc: 'districtDesc'
} as const;

export type OrganisationOrderBy = (typeof OrganisationOrderBy)[keyof typeof OrganisationOrderBy];

export type ChangePasswordInput = {
  password: Scalars['String'];
  newPassword: Scalars['String'];
};

export const StandardisationRole = {
  coordinator: 'coordinator',
  analyst: 'analyst'
} as const;

export type StandardisationRole = (typeof StandardisationRole)[keyof typeof StandardisationRole];

export type StandardisationAccessInput = {
  userId: Scalars['ID'];
  role?: Maybe<StandardisationRole>;
};

export type UserOneMenuInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  input: ManualManyInput;
}>;

export type UserOneMenuInfoQuery = {
  userOne: { __typename: 'User'; id: string; fullName: string; role: Role };
  manualMany: Array<{ __typename: 'Manual'; id: string; code: string; name: string; path: string }>;
};

export type MenuManualUrlMutationVariables = Exact<{
  path: Scalars['String'];
}>;

export type MenuManualUrlMutation = { manualUrl: string };

export type IdentityQueryVariables = Exact<{ [key: string]: never }>;

export type IdentityQuery = {
  identity?: Maybe<{
    __typename: 'User';
    id: string;
    role: Role;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    status: UserStatus;
    standardisationRole?: Maybe<StandardisationRole>;
  }>;
};

export type UserAutocompleteReassignmentQueryVariables = Exact<{
  term?: Maybe<Scalars['String']>;
}>;

export type UserAutocompleteReassignmentQuery = {
  userMany: {
    __typename: 'UserPage';
    total: number;
    items: Array<{
      __typename: 'User';
      id: string;
      fullName: string;
      organisation?: Maybe<{ __typename: 'Organisation'; id: string; name: string }>;
      position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
    }>;
  };
};

export type UserOneRedirectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserOneRedirectQuery = {
  userOne: {
    __typename: 'User';
    id: string;
    fullName: string;
    role: Role;
    status: UserStatus;
    organisation?: Maybe<{ __typename: 'Organisation'; id: string; name: string }>;
    position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
  };
};

export type LatestDiagnosticResultsQueryVariables = Exact<{
  input: TestingFilterInput;
}>;

export type LatestDiagnosticResultsQuery = {
  testingMany: {
    __typename: 'TestingPage';
    total: number;
    items: Array<{
      __typename: 'Testing';
      id: string;
      code: string;
      label?: Maybe<string>;
      createdAt: string;
    }>;
  };
  announcementOne: {
    __typename: 'Announcement';
    id: string;
    announcements: Array<{
      __typename: 'Announcements';
      title: string;
      text: string;
      date: string;
    }>;
  };
};

export type TestingOneEvaluationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TestingOneEvaluationQuery = {
  testingOne: {
    __typename: 'Testing';
    id: string;
    code: string;
    createdAt: string;
    originalToken?: Maybe<string>;
    label?: Maybe<string>;
    testingDate?: Maybe<any>;
    student: {
      __typename: 'Student';
      fullName: string;
      dateOfBirth?: Maybe<any>;
      gender?: Maybe<Gender>;
    };
    details: {
      __typename: 'TestingDetails';
      ratingSlovakLanguage?: Maybe<number>;
      ratingMaths?: Maybe<number>;
      ratingTechnicalEducation?: Maybe<number>;
      ratingArtEducation?: Maybe<number>;
      grade?: Maybe<number>;
      postponement?: Maybe<number>;
      repeatedGrade?: Maybe<number>;
      ratingForeignLanguage?: Maybe<number>;
      motherEducation?: Maybe<number>;
      fatherEducation?: Maybe<number>;
      t184subject?: Maybe<string>;
      readingEvaluation?: Maybe<number>;
    };
    evaluation?: Maybe<
      | ({ __typename: 'T8Evaluation' } & T8EvaluationFragment)
      | ({ __typename: 'T184Evaluation' } & T184EvaluationFragment)
      | ({ __typename: 'T21Evaluation' } & T21EvaluationFragment)
      | ({ __typename: 'T216Evaluation' } & T216EvaluationFragment)
      | ({ __typename: 'T252Evaluation' } & T252EvaluationFragment)
      | ({ __typename: 'T41Evaluation' } & T41EvaluationFragment)
      | ({ __typename: 'T309Evaluation' } & T309EvaluationFragment)
      | ({ __typename: 'T239Evaluation' } & T239EvaluationFragment)
      | ({ __typename: 'T202Evaluation' } & T202EvaluationFragment)
      | ({ __typename: 'SmkzdEvaluation' } & SmkzdEvaluationFragment)
      | ({ __typename: 'OtprEvaluation' } & OtprEvaluationFragment)
      | ({ __typename: 'T95Evaluation' } & T95EvaluationFragment)
      | ({ __typename: 'DefaultEvaluation' } & DefaultEvaluationFragment)
    >;
  };
};

export type DiagnosticResultsQueryVariables = Exact<{
  input: TestingFilterInput;
}>;

export type DiagnosticResultsQuery = {
  testingMany: {
    __typename: 'TestingPage';
    total: number;
    items: Array<{
      __typename: 'Testing';
      id: string;
      code: string;
      originalToken?: Maybe<string>;
      createdAt: string;
      student: {
        __typename: 'Student';
        id: string;
        firstName: string;
        lastName: string;
        fullName: string;
        dateOfBirth?: Maybe<any>;
        gender?: Maybe<Gender>;
        school?: Maybe<string>;
        diagnosis?: Maybe<{ __typename: 'Diagnosis'; content?: Maybe<string> }>;
        owner?: Maybe<{ __typename: 'User'; id: string; fullName: string }>;
      };
      user: { __typename: 'User'; id: string };
    }>;
  };
};

export type ManualManyQueryVariables = Exact<{
  input: ManualManyInput;
}>;

export type ManualManyQuery = {
  manualMany: Array<{ __typename: 'Manual'; id: string; code: string; name: string; path: string }>;
};

export type ManualUrlMutationVariables = Exact<{
  path: Scalars['String'];
}>;

export type ManualUrlMutation = { manualUrl: string };

export type DemoTestingMutationVariables = Exact<{
  input: DemoTestingInput;
}>;

export type DemoTestingMutation = { demoTesting: { __typename: 'DemoTesting'; url: string } };

export type NewGroupTestingMutationVariables = Exact<{
  input: NewGroupTestingInput;
}>;

export type NewGroupTestingMutation = {
  newGroupTesting: { __typename: 'GroupTesting'; id: string };
};

export type NewGroupStudentQueryVariables = Exact<{
  input: StudentFilterInput;
}>;

export type NewGroupStudentQuery = {
  newStudentMany: {
    __typename: 'StudentPage';
    total: number;
    items: Array<{ __typename: 'Student'; id: string; fullName: string }>;
  };
};

export type SelectedGroupTestingMutationVariables = Exact<{
  input: SelectedGroupTestingInput;
}>;

export type SelectedGroupTestingMutation = {
  selectedGroupTesting: { __typename: 'GroupTesting'; id: string };
};

export type GroupManyQueryVariables = Exact<{
  input: GroupFilterInput;
}>;

export type GroupManyQuery = {
  groupMany: {
    __typename: 'GroupPage';
    total: number;
    items: Array<{ __typename: 'Group'; id: string; name: string }>;
  };
};

export type IndividualTestingMutationVariables = Exact<{
  input: TestingInput;
}>;

export type IndividualTestingMutation = {
  individualTesting: { __typename: 'Testing'; id: string };
};

export type IndividualStudentQueryVariables = Exact<{
  input: StudentFilterInput;
}>;

export type IndividualStudentQuery = {
  newStudentMany: {
    __typename: 'StudentPage';
    total: number;
    items: Array<{ __typename: 'Student'; id: string; fullName: string; status: StudentStatus }>;
  };
};

export type RemoteTestingMutationVariables = Exact<{
  input: TestingInput;
}>;

export type RemoteTestingMutation = { remoteTesting: { __typename: 'Testing'; id: string } };

export type RemoteStudentQueryVariables = Exact<{
  input: StudentFilterInput;
}>;

export type RemoteStudentQuery = {
  newStudentMany: {
    __typename: 'StudentPage';
    total: number;
    items: Array<{ __typename: 'Student'; id: string; fullName: string }>;
  };
};

export type DefaultEvaluationFragment = { __typename: 'DefaultEvaluation'; result: string };

export type OtprEvaluationFragment = {
  __typename: 'OtprEvaluation';
  variant: string;
  s: number;
  roughScore: number;
  practice: number;
  practice2?: Maybe<number>;
  generalN: number;
  generalPercentile: number;
  groupN: number;
  groupPercentile: number;
  general1N?: Maybe<number>;
  general1Percentile?: Maybe<number>;
  group1N?: Maybe<number>;
  group1Percentile?: Maybe<number>;
  general2N?: Maybe<number>;
  general2Percentile?: Maybe<number>;
  group2N?: Maybe<number>;
  group2Percentile?: Maybe<number>;
  result: string;
  interrupted?: Maybe<boolean>;
  interruptionReason?: Maybe<string>;
  ended?: Maybe<boolean>;
  endingReason?: Maybe<string>;
  downgraded?: Maybe<boolean>;
  communication?: Maybe<string>;
  notes?: Maybe<string>;
};

export type SmkzdEvaluationFragment = {
  __typename: 'SmkzdEvaluation';
  n: number;
  percentil: number;
  sten: number;
  roughScore: number;
  percentilTotal: number;
  stenTotal: number;
};

export type T184EvaluationFragment = {
  __typename: 'T184Evaluation';
  additionalText: string;
  version: string;
  items: Array<{
    __typename: 'T184EvaluationItem';
    label: string;
    totalRoughScore: number;
    percentileByGenderAndAge: string;
  }>;
};

export type T202EvaluationFragment = {
  __typename: 'T202Evaluation';
  articleId: string;
  firstMinuteWord: number;
  mistakesFirstMinute: number;
  scoreFirstMinute: number;
  mistakesPercentageFirstMinute: number;
  secondMinuteWord: number;
  mistakesSecondMinute: number;
  scoreSecondMinute: number;
  mistakesPercentageSecondMinute: number;
  thirdMinuteWord: number;
  mistakesThirdMinute: number;
  scoreThirdMinute: number;
  mistakesPercentageThirdMinute: number;
  mistakesTwoLetterWords: number;
  mistakesThreeLetterWords: number;
  sten: number;
  evaluationMistakesFirstMinute: string;
  evaluationMistakesPercentageFirstMin: string;
  readingLocalizationOne?: Maybe<string>;
  readingLocalizationTwo?: Maybe<string>;
  readingLocalizationThree?: Maybe<string>;
  readingLocalizationFour?: Maybe<string>;
  readingLocalizationFive?: Maybe<string>;
  readingLocalizationSix?: Maybe<string>;
  readingLocalizationSeven?: Maybe<string>;
  readingReproduction?: Maybe<number>;
  readingReproductionScale?: Maybe<number>;
  readingSense?: Maybe<number>;
  wayOfReading?: Maybe<number>;
  durationEntry?: Maybe<number>;
  errorShapes?: Maybe<Array<number>>;
  t202duration?: Maybe<number>;
};

export type T216EvaluationFragment = {
  __typename: 'T216Evaluation';
  data: Array<{
    __typename: 'T216EvaluationItem';
    label: string;
    roughScore: number;
    sten: number;
  }>;
};

export type T216AnswersResultsQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T216AnswersResultsQuery = {
  t216Diagnostics?: Maybe<{
    __typename: 'T216';
    id?: Maybe<string>;
    questions: Array<{ __typename: 'T216Question'; id: string; task: string }>;
    answers: Array<{ __typename: 'T216AnswerMany'; id: string; radioValue: number }>;
  }>;
};

export type T21EvaluationFragment = {
  __typename: 'T21Evaluation';
  score: {
    __typename: 'T21RoughScore';
    p: number;
    e: number;
    n: number;
    l: number;
    i?: Maybe<number>;
    s?: Maybe<number>;
  };
  percentile: {
    __typename: 'T21Percentile';
    p?: Maybe<number>;
    e?: Maybe<number>;
    n?: Maybe<number>;
    l?: Maybe<number>;
    i?: Maybe<number>;
    s?: Maybe<number>;
  };
};

export type T239EvaluationFragment = {
  __typename: 'T239Evaluation';
  ad: number;
  aa: number;
  aas: number;
  vd: number;
  verticalSymetryErrors: number;
  identityErrors: number;
  otherSymetryErrors: number;
  plo: number;
  amk: number;
  m: number;
  conclusion?: Maybe<string>;
  stenItems: Array<{ __typename: 'T239Response'; label: string; value?: Maybe<string> }>;
  percentileItems: Array<{ __typename: 'T239Response'; label: string; value?: Maybe<string> }>;
  answerItems: Array<{ __typename: 'T239Response'; label: string; value?: Maybe<string> }>;
};

export type T252EvaluationFragment = {
  __typename: 'T252Evaluation';
  ho: Array<{
    __typename: 'T252EvaluationItem';
    id: string;
    label: string;
    roughScore: number;
    degree: number;
    stenScore: string;
    percentile: number;
  }>;
  po: Array<{
    __typename: 'T252EvaluationItem';
    id: string;
    label: string;
    roughScore: number;
    degree: number;
    stenScore: string;
    percentile: number;
  }>;
  mo: Array<{
    __typename: 'T252EvaluationItem';
    id: string;
    label: string;
    roughScore: number;
    degree: number;
    stenScore: string;
    percentile: number;
  }>;
};

export type T309EvaluationFragment = {
  __typename: 'T309Evaluation';
  items: Array<{
    __typename: 'T309EvaluationItem';
    label: string;
    totalRoughScore: number;
    percentile: number;
  }>;
};

export type T41EvaluationFragment = {
  __typename: 'T41Evaluation';
  duration: number;
  oldSten?: Maybe<number>;
  resultPercentile?: Maybe<string>;
  testN?: Maybe<number>;
};

export type T41AnswersResultsQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T41AnswersResultsQuery = {
  t41Diagnostics?: Maybe<{
    __typename: 'T41';
    id?: Maybe<string>;
    answers?: Maybe<{ __typename: 'T41Answer'; duration: number; version: string }>;
  }>;
};

export type T8EvaluationFragment = {
  __typename: 'T8Evaluation';
  versionA: {
    __typename: 'T8EvaluationVersionA';
    totalRoughScore: number;
    degree: string;
    percentile: number;
  };
  versionB?: Maybe<{ __typename: 'T8EvaluationVersionB'; totalRoughScore: number; degree: string }>;
};

export type T8AnswersResultsQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T8AnswersResultsQuery = {
  t8Diagnostics?: Maybe<{
    __typename: 'T8';
    id?: Maybe<string>;
    v1: {
      __typename: 'T8V1';
      questionGroups: {
        __typename: 'T8V1QuestionGroups';
        table1: {
          __typename: 'T8QuestionsTable1';
          questions: Array<{ __typename: 'T8QuestionGroup1'; id: string; task: string }>;
        };
      };
    };
    v2: {
      __typename: 'T8V2';
      questionGroups: {
        __typename: 'T8V2QuestionGroups';
        table1: {
          __typename: 'T8QuestionsTable1';
          questions: Array<{ __typename: 'T8QuestionGroup1'; id: string; task: string }>;
        };
        table2: {
          __typename: 'T8QuestionsTable2';
          questions: Array<{ __typename: 'T8QuestionGroup2'; id: string; task: string }>;
        };
      };
    };
    answers?: Maybe<{
      __typename: 'T8AnswerMany';
      v1?: Maybe<{
        __typename: 'T8V1Answers';
        answersTable1: Array<{
          __typename: 'T8AnswersTable1';
          id: string;
          radioValue: number;
          answer?: Maybe<string>;
        }>;
      }>;
      v2?: Maybe<{
        __typename: 'T8V2Answers';
        answersTable1: Array<{
          __typename: 'T8AnswersTable1';
          id: string;
          radioValue: number;
          answer?: Maybe<string>;
        }>;
        answersTable2: Array<{
          __typename: 'T8V2AnswersTable2';
          id: string;
          match: boolean;
          notMatch: string;
        }>;
      }>;
    }>;
  }>;
};

export type T95EvaluationFragment = {
  __typename: 'T95Evaluation';
  attention: string;
  ciq?: Maybe<string>;
  viq?: Maybe<string>;
  niq?: Maybe<string>;
  hearingDisability: boolean;
  sightDisability: boolean;
  leftEye?: Maybe<string>;
  rightEye?: Maybe<string>;
  notes?: Maybe<string>;
  take1: {
    __typename: 'T95ResultItem';
    correct: number;
    repetitions: number;
    confabulations: number;
  };
  take2: {
    __typename: 'T95ResultItem';
    correct: number;
    repetitions: number;
    confabulations: number;
  };
  take3: {
    __typename: 'T95ResultItem';
    correct: number;
    repetitions: number;
    confabulations: number;
  };
  take4: {
    __typename: 'T95ResultItem';
    correct: number;
    repetitions: number;
    confabulations: number;
  };
  take5: {
    __typename: 'T95ResultItem';
    correct: number;
    repetitions: number;
    confabulations: number;
  };
  take6: {
    __typename: 'T95ResultItemWithAddition';
    correct: number;
    repetitions: number;
    confabulations: number;
    percentil: string;
    n: string;
  };
  take7: {
    __typename: 'T95ResultItemWithAddition';
    correct: number;
    repetitions: number;
    confabulations: number;
    percentil: string;
    n: string;
  };
  setA: {
    __typename: 'T95ResultItemWithAddition';
    correct: number;
    repetitions: number;
    confabulations: number;
    percentil: string;
    n: string;
  };
  setB: {
    __typename: 'T95ResultItem';
    correct: number;
    repetitions: number;
    confabulations: number;
  };
};

export type SendGroupTestingMutationVariables = Exact<{
  input: SendTestingInput;
}>;

export type SendGroupTestingMutation = { sendTesting: string };

export type GroupTestingQueryVariables = Exact<{
  input: TestingFilterInput;
}>;

export type GroupTestingQuery = {
  testingMany: {
    __typename: 'TestingPage';
    total: number;
    items: Array<{
      __typename: 'Testing';
      id: string;
      code: string;
      expiresAt?: Maybe<any>;
      student: { __typename: 'Student'; id: string; fullName: string };
    }>;
  };
};

export type SendTestingMutationVariables = Exact<{
  input: SendTestingInput;
}>;

export type SendTestingMutation = { sendTesting: string };

export type IndividualTestingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IndividualTestingQuery = {
  testingOne: {
    __typename: 'Testing';
    id: string;
    code: string;
    expiresAt?: Maybe<any>;
    student: { __typename: 'Student'; id: string; fullName: string };
  };
};

export type DiagnosticManyQueryVariables = Exact<{
  id: Scalars['ID'];
  input: DiagnosticFilterInput;
}>;

export type DiagnosticManyQuery = {
  diagnosticMany: {
    __typename: 'DiagnosticPage';
    total: number;
    items: Array<{ __typename: 'Diagnostic'; code: string; label: string }>;
  };
  userOne: {
    __typename: 'User';
    id: string;
    organisation?: Maybe<{
      __typename: 'Organisation';
      id: string;
      group?: Maybe<{ __typename: 'UserGroup'; id: string; testIds: Array<string> }>;
    }>;
    group?: Maybe<{ __typename: 'UserGroup'; id: string; testIds: Array<string> }>;
    position: Array<{ __typename: 'UserPosition'; id: string; testIds: Array<string> }>;
  };
};

export type UserGroupManyQueryVariables = Exact<{
  input?: Maybe<UserGroupManyInput>;
}>;

export type UserGroupManyQuery = {
  userGroupMany: {
    __typename: 'UserGroupPage';
    total: number;
    items: Array<{ __typename: 'UserGroup'; id: string; name: string }>;
  };
};

export type OrganisationAutocompleteQueryVariables = Exact<{
  input?: Maybe<OrganisationFilterInput>;
}>;

export type OrganisationAutocompleteQuery = {
  organisationMany: {
    __typename: 'OrganisationPage';
    total: number;
    items: Array<{
      __typename: 'Organisation';
      id: string;
      name: string;
      city?: Maybe<string>;
      line1?: Maybe<string>;
    }>;
  };
};

export type UserAutocompleteQueryVariables = Exact<{
  input?: Maybe<UserFilterInput>;
}>;

export type UserAutocompleteQuery = {
  userMany: {
    __typename: 'UserPage';
    total: number;
    items: Array<{ __typename: 'User'; id: string; fullName: string }>;
  };
};

export type UserGroupCreateMutationVariables = Exact<{
  input: UserGroupInput;
}>;

export type UserGroupCreateMutation = {
  userGroupCreate: { __typename: 'UserGroup'; id: string; name: string };
};

export type UserGroupEditMutationVariables = Exact<{
  input: UserGroupInput;
  id: Scalars['ID'];
}>;

export type UserGroupEditMutation = {
  userGroupEdit: { __typename: 'UserGroup'; id: string; name: string };
};

export type UserGroupRemoveMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserGroupRemoveMutation = { userGroupRemove?: Maybe<void> };

export type UserGroupOneQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserGroupOneQuery = {
  userGroupOne: {
    __typename: 'UserGroup';
    id: string;
    name: string;
    videoIds: Array<string>;
    testIds: Array<string>;
    userIds: Array<{ __typename: 'User'; id: string; fullName: string }>;
    organisationIds: Array<{
      __typename: 'Organisation';
      id: string;
      name: string;
      city?: Maybe<string>;
      line1?: Maybe<string>;
    }>;
  };
};

export type PositionGroupManyQueryVariables = Exact<{ [key: string]: never }>;

export type PositionGroupManyQuery = {
  positionGroupMany: Array<{ __typename: 'PositionGroup'; id: string; name: string }>;
};

export type PositionGroupOneQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PositionGroupOneQuery = {
  positionGroupOne: {
    __typename: 'PositionGroup';
    id: string;
    name: string;
    videoIds: Array<string>;
    testIds: Array<string>;
  };
};

export type PositionGroupEditMutationVariables = Exact<{
  input: PositionGroupInput;
}>;

export type PositionGroupEditMutation = {
  positionGroupEdit: {
    __typename: 'PositionGroup';
    id: string;
    name: string;
    videoIds: Array<string>;
    testIds: Array<string>;
  };
};

export type OwnerOrganisationOneQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OwnerOrganisationOneQuery = {
  userOne: {
    __typename: 'User';
    organisation?: Maybe<{
      __typename: 'Organisation';
      id: string;
      name: string;
      line1?: Maybe<string>;
      city?: Maybe<string>;
      region?: Maybe<string>;
      district?: Maybe<string>;
      requestedChanges?: Maybe<{
        __typename: 'RequestedChanges';
        name?: Maybe<string>;
        line1?: Maybe<string>;
        city?: Maybe<string>;
        region?: Maybe<string>;
        district?: Maybe<string>;
      }>;
    }>;
  };
};

export type RequestOrganisationNameMyProfileMutationVariables = Exact<{
  input: RequestedOrganisationChangesInput;
}>;

export type RequestOrganisationNameMyProfileMutation = {
  requestOrganisationName: {
    __typename: 'User';
    id: string;
    organisation?: Maybe<{
      __typename: 'Organisation';
      id: string;
      name: string;
      line1?: Maybe<string>;
      city?: Maybe<string>;
      region?: Maybe<string>;
      district?: Maybe<string>;
      requestedChanges?: Maybe<{
        __typename: 'RequestedChanges';
        name?: Maybe<string>;
        line1?: Maybe<string>;
        city?: Maybe<string>;
        region?: Maybe<string>;
        district?: Maybe<string>;
      }>;
    }>;
  };
};

export type OrganisationOneAcceptRequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrganisationOneAcceptRequestQuery = {
  organisationOne: {
    __typename: 'Organisation';
    id: string;
    name: string;
    line1?: Maybe<string>;
    city?: Maybe<string>;
    region?: Maybe<string>;
    district?: Maybe<string>;
    requestedChanges?: Maybe<{
      __typename: 'RequestedChanges';
      name?: Maybe<string>;
      line1?: Maybe<string>;
      city?: Maybe<string>;
      region?: Maybe<string>;
      district?: Maybe<string>;
    }>;
  };
};

export type AcceptOrganisationNameChangeMutationVariables = Exact<{
  input: AcceptOrganisationChangesInput;
}>;

export type AcceptOrganisationNameChangeMutation = {
  acceptOrganisationNameChange: {
    __typename: 'Organisation';
    id: string;
    name: string;
    requestedChanges?: Maybe<{
      __typename: 'RequestedChanges';
      name?: Maybe<string>;
      city?: Maybe<string>;
      line1?: Maybe<string>;
      region?: Maybe<string>;
      district?: Maybe<string>;
    }>;
  };
};

export type AddOrganisationMutationVariables = Exact<{
  input: AddOrganisationInput;
}>;

export type AddOrganisationMutation = {
  addOrganisation: { __typename: 'Organisation'; id: string; name: string };
};

export type OrganisationOneQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrganisationOneQuery = {
  organisationOne: {
    __typename: 'Organisation';
    id: string;
    name: string;
    status: OrganisationStatus;
    line1?: Maybe<string>;
    city?: Maybe<string>;
    region?: Maybe<string>;
    district?: Maybe<string>;
  };
};

export type EditOrganisationMutationVariables = Exact<{
  input: EditOrganisationInput;
}>;

export type EditOrganisationMutation = {
  editOrganisation: {
    __typename: 'Organisation';
    id: string;
    name: string;
    status: OrganisationStatus;
  };
};

export type OrganisationManyQueryVariables = Exact<{
  input: OrganisationFilterInput;
  orderBy?: Maybe<OrganisationOrderBy>;
}>;

export type OrganisationManyQuery = {
  organisationMany: {
    __typename: 'OrganisationPage';
    total: number;
    items: Array<{
      __typename: 'Organisation';
      id: string;
      name: string;
      city?: Maybe<string>;
      line1?: Maybe<string>;
      region?: Maybe<string>;
      district?: Maybe<string>;
      requestedChanges?: Maybe<{
        __typename: 'RequestedChanges';
        name?: Maybe<string>;
        city?: Maybe<string>;
        line1?: Maybe<string>;
        region?: Maybe<string>;
        district?: Maybe<string>;
      }>;
    }>;
  };
};

export type StudentOneFragment = {
  __typename: 'Student';
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  gender?: Maybe<Gender>;
  status: StudentStatus;
  birthIdentificationNumber?: Maybe<string>;
  dateOfBirth?: Maybe<any>;
  grade?: Maybe<number>;
  otherData?: Maybe<string>;
  language?: Maybe<string>;
  nationality?: Maybe<string>;
  school?: Maybe<string>;
  address?: Maybe<{ __typename: 'Address'; line1: string; city: string; postCode: string }>;
  legalRepresentative?: Maybe<{
    __typename: 'LegalRepresentative';
    fullName: string;
    phoneNumber: string;
    email: string;
  }>;
};

export type EditStudentMutationVariables = Exact<{
  input: EditStudentInput;
}>;

export type EditStudentMutation = { editStudent: { __typename: 'Student' } & StudentOneFragment };

export type StudentStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: StudentStatus;
}>;

export type StudentStatusMutation = {
  updateStudentStatus: { __typename: 'Student' } & StudentOneFragment;
};

export type ReassignmentStudentMutationVariables = Exact<{
  studentId: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type ReassignmentStudentMutation = {
  reassignmentStudent: { __typename: 'Student'; id: string };
};

export type StudentDiagnosisDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StudentDiagnosisDetailQuery = {
  studentOne: {
    __typename: 'Student';
    id: string;
    firstName: string;
    lastName: string;
    diagnosis?: Maybe<{ __typename: 'Diagnosis'; title?: Maybe<string>; content?: Maybe<string> }>;
  };
};

export type EditStudentDiagnosisMutationVariables = Exact<{
  input: EditStudentDiagnosisInput;
}>;

export type EditStudentDiagnosisMutation = {
  editStudentDiagnosis: {
    __typename: 'Student';
    id: string;
    firstName: string;
    lastName: string;
    diagnosis?: Maybe<{ __typename: 'Diagnosis'; title?: Maybe<string>; content?: Maybe<string> }>;
  };
};

export type StudentDiagnosticsQueryVariables = Exact<{
  input: TestingFilterInput;
}>;

export type StudentDiagnosticsQuery = {
  testingMany: {
    __typename: 'TestingPage';
    total: number;
    items: Array<{
      __typename: 'Testing';
      id: string;
      code: string;
      label?: Maybe<string>;
      originalToken?: Maybe<string>;
      createdAt: string;
      student: { __typename: 'Student'; id: string; fullName: string };
      user: { __typename: 'User'; id: string; fullName: string };
    }>;
  };
};

export type StudentOneDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StudentOneDetailQuery = { studentOne: { __typename: 'Student' } & StudentOneFragment };

export type AddStudentMutationVariables = Exact<{
  input: AddStudentInput;
}>;

export type AddStudentMutation = { addStudent: { __typename: 'Student'; id: string } };

export type StudentManyOnAddQueryVariables = Exact<{
  input: StudentFilterInput;
}>;

export type StudentManyOnAddQuery = {
  newStudentMany: {
    __typename: 'StudentPage';
    total: number;
    items: Array<{
      __typename: 'Student';
      id: string;
      fullName: string;
      gender?: Maybe<Gender>;
      school?: Maybe<string>;
      status: StudentStatus;
      owner?: Maybe<{ __typename: 'User'; id: string; fullName: string }>;
    }>;
  };
};

export type StudentManyQueryVariables = Exact<{
  input: StudentFilterInput;
  userId: Scalars['ID'];
}>;

export type StudentManyQuery = {
  userOne: {
    __typename: 'User';
    id: string;
    position: Array<{ __typename: 'UserPosition'; id: string }>;
  };
  newStudentMany: {
    __typename: 'StudentPage';
    total: number;
    items: Array<{
      __typename: 'Student';
      id: string;
      fullName: string;
      gender?: Maybe<Gender>;
      school?: Maybe<string>;
      status: StudentStatus;
      owner?: Maybe<{ __typename: 'User'; id: string; fullName: string }>;
    }>;
  };
};

export type TestResultsQueryVariables = Exact<{
  input: TestingFilterInput;
}>;

export type TestResultsQuery = {
  testingMany: {
    __typename: 'TestingPage';
    total: number;
    items: Array<{
      __typename: 'Testing';
      id: string;
      code: string;
      originalToken?: Maybe<string>;
      createdAt: string;
      student: {
        __typename: 'Student';
        id: string;
        firstName: string;
        lastName: string;
        fullName: string;
        dateOfBirth?: Maybe<any>;
        gender?: Maybe<Gender>;
        school?: Maybe<string>;
        diagnosis?: Maybe<{ __typename: 'Diagnosis'; content?: Maybe<string> }>;
        owner?: Maybe<{ __typename: 'User'; id: string; fullName: string }>;
      };
    }>;
  };
};

export type ResultOneEvaluationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ResultOneEvaluationQuery = {
  testingOne: {
    __typename: 'Testing';
    id: string;
    code: string;
    createdAt: string;
    originalToken?: Maybe<string>;
    student: {
      __typename: 'Student';
      fullName: string;
      dateOfBirth?: Maybe<any>;
      gender?: Maybe<Gender>;
    };
    details: {
      __typename: 'TestingDetails';
      ratingSlovakLanguage?: Maybe<number>;
      ratingMaths?: Maybe<number>;
      ratingTechnicalEducation?: Maybe<number>;
      ratingArtEducation?: Maybe<number>;
      grade?: Maybe<number>;
      postponement?: Maybe<number>;
      repeatedGrade?: Maybe<number>;
      ratingForeignLanguage?: Maybe<number>;
      motherEducation?: Maybe<number>;
      fatherEducation?: Maybe<number>;
      t184subject?: Maybe<string>;
    };
    evaluation?: Maybe<
      | ({ __typename: 'T8Evaluation' } & T8EvaluationFragment)
      | ({ __typename: 'T184Evaluation' } & T184EvaluationFragment)
      | ({ __typename: 'T21Evaluation' } & T21EvaluationFragment)
      | ({ __typename: 'T216Evaluation' } & T216EvaluationFragment)
      | ({ __typename: 'T252Evaluation' } & T252EvaluationFragment)
      | ({ __typename: 'T41Evaluation' } & T41EvaluationFragment)
      | ({ __typename: 'T309Evaluation' } & T309EvaluationFragment)
      | ({ __typename: 'T239Evaluation' } & T239EvaluationFragment)
      | ({ __typename: 'T202Evaluation' } & T202EvaluationFragment)
      | ({ __typename: 'SmkzdEvaluation' } & SmkzdEvaluationFragment)
      | ({ __typename: 'OtprEvaluation' } & OtprEvaluationFragment)
      | ({ __typename: 'T95Evaluation' } & T95EvaluationFragment)
      | ({ __typename: 'DefaultEvaluation' } & DefaultEvaluationFragment)
    >;
  };
};

export type AddUserMutationVariables = Exact<{
  input: InviteInput;
}>;

export type AddUserMutation = {
  addUser: {
    __typename: 'User';
    id: string;
    firstName: string;
    lastName: string;
    title?: Maybe<string>;
    phoneNumber?: Maybe<string>;
    email: string;
    role: Role;
    organisation?: Maybe<{ __typename: 'Organisation'; id: string }>;
    position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
  };
};

export type UserEditPositionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserEditPositionQuery = {
  userOne: {
    __typename: 'User';
    id: string;
    role: Role;
    position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
    requestedPosition: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
  };
};

export type PositionGroupOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type PositionGroupOptionsQuery = {
  positionGroups: Array<{ __typename: 'PositionGroupRadio'; label: string; value: string }>;
};

export type PositionChangeMutationVariables = Exact<{
  userId: Scalars['ID'];
  positionIds: Array<Scalars['String']> | Scalars['String'];
  role?: Maybe<Role>;
}>;

export type PositionChangeMutation = {
  positionChange: {
    __typename: 'User';
    id: string;
    role: Role;
    position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
    requestedPosition: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
  };
};

export type RejectPositionChangeUserPageMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type RejectPositionChangeUserPageMutation = {
  rejectPositionChange: {
    __typename: 'User';
    id: string;
    fullName: string;
    status: UserStatus;
    organisation?: Maybe<{ __typename: 'Organisation'; id: string; name: string }>;
    position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
    requestedPosition: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
  };
};

export type StandardisationAccessOneQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type StandardisationAccessOneQuery = {
  userStandardisationAccessOne: {
    __typename: 'StandardisationAccess';
    role?: Maybe<StandardisationRole>;
  };
};

export type StandardisationAccessManyQueryVariables = Exact<{ [key: string]: never }>;

export type StandardisationAccessManyQuery = {
  userStandardisationAccessMany: Array<{ __typename: 'Option'; label: string; value: string }>;
};

export type StandardisationAccessEditMutationVariables = Exact<{
  input: StandardisationAccessInput;
}>;

export type StandardisationAccessEditMutation = {
  userStandardisationAccessEdit: { __typename: 'User'; id: string };
};

export type OrganisationManyAutocompleteQueryVariables = Exact<{
  input: OrganisationFilterInput;
}>;

export type OrganisationManyAutocompleteQuery = {
  organisationMany: {
    __typename: 'OrganisationPage';
    total: number;
    items: Array<{
      __typename: 'Organisation';
      id: string;
      name: string;
      line1?: Maybe<string>;
      city?: Maybe<string>;
    }>;
  };
};

export type ProfilePositionGroupOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ProfilePositionGroupOptionsQuery = {
  positionGroups: Array<{ __typename: 'PositionGroupRadio'; label: string; value: string }>;
};

export type AddUserStudentMutationVariables = Exact<{
  input: AddStudentInput;
}>;

export type AddUserStudentMutation = { addStudent: { __typename: 'Student'; id: string } };

export type UserOneProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserOneProfileQuery = {
  userOne: {
    __typename: 'User';
    requestedChanges?: Maybe<{
      __typename: 'UserRequestedChanges';
      firstName?: Maybe<string>;
      lastName?: Maybe<string>;
      title?: Maybe<string>;
      phoneNumber?: Maybe<string>;
    }>;
  } & UserOneFragment;
};

export type AcceptUserChangeMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type AcceptUserChangeMutation = {
  acceptUserChange: {
    __typename: 'User';
    id: string;
    fullName: string;
    createdAt?: Maybe<string>;
    status: UserStatus;
    organisation?: Maybe<{
      __typename: 'Organisation';
      id: string;
      name: string;
      district?: Maybe<string>;
      region?: Maybe<string>;
      city?: Maybe<string>;
    }>;
    position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
    requestedPosition: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
    requestedChanges?: Maybe<{
      __typename: 'UserRequestedChanges';
      firstName?: Maybe<string>;
      lastName?: Maybe<string>;
      title?: Maybe<string>;
      phoneNumber?: Maybe<string>;
    }>;
  };
};

export type DeclineUserChangeMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type DeclineUserChangeMutation = {
  declineUserChange: {
    __typename: 'User';
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
    requestedChanges?: Maybe<{
      __typename: 'UserRequestedChanges';
      firstName?: Maybe<string>;
      lastName?: Maybe<string>;
      title?: Maybe<string>;
      phoneNumber?: Maybe<string>;
    }>;
  };
};

export type MyUserOneFragment = {
  __typename: 'User';
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  title?: Maybe<string>;
  role: Role;
  phoneNumber?: Maybe<string>;
  email: string;
  status: UserStatus;
  organisation?: Maybe<{
    __typename: 'Organisation';
    id: string;
    name: string;
    city?: Maybe<string>;
    line1?: Maybe<string>;
  }>;
  position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
  requestedChanges?: Maybe<{
    __typename: 'UserRequestedChanges';
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    title?: Maybe<string>;
    phoneNumber?: Maybe<string>;
  }>;
};

export type UserOneMyProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserOneMyProfileQuery = { userOne: { __typename: 'User' } & MyUserOneFragment };

export type RequestEditUserDataMutationVariables = Exact<{
  input: RequestedUserChangesInput;
}>;

export type RequestEditUserDataMutation = {
  requestEditUser: { __typename: 'User' } & MyUserOneFragment;
};

export type UserOneFragment = {
  __typename: 'User';
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  title?: Maybe<string>;
  role: Role;
  phoneNumber?: Maybe<string>;
  email: string;
  status: UserStatus;
  organisation?: Maybe<{
    __typename: 'Organisation';
    id: string;
    name: string;
    city?: Maybe<string>;
    line1?: Maybe<string>;
  }>;
  position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
};

export type EditUserProfileMutationVariables = Exact<{
  input: EditUserInput;
}>;

export type EditUserProfileMutation = { editUser: { __typename: 'User' } & UserOneFragment };

export type UserStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: UserStatus;
}>;

export type UserStatusMutation = { updateUserStatus: { __typename: 'User' } & UserOneFragment };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveUserMutation = { removeUser: { __typename: 'User' } & UserOneFragment };

export type UserStudentManyQueryVariables = Exact<{
  input: StudentFilterInput;
  userId: Scalars['ID'];
}>;

export type UserStudentManyQuery = {
  userOne: {
    __typename: 'User';
    id: string;
    position: Array<{ __typename: 'UserPosition'; id: string }>;
  };
  newStudentMany: {
    __typename: 'StudentPage';
    total: number;
    items: Array<{
      __typename: 'Student';
      id: string;
      fullName: string;
      gender?: Maybe<Gender>;
      school?: Maybe<string>;
      status: StudentStatus;
      owner?: Maybe<{ __typename: 'User'; id: string; fullName: string }>;
    }>;
  };
};

export type ReassignmentStudentManyMutationVariables = Exact<{
  ownerId: Scalars['ID'];
  newOwnerId: Scalars['ID'];
}>;

export type ReassignmentStudentManyMutation = { reassignmentStudentMany?: Maybe<void> };

export type UserManyQueryVariables = Exact<{
  input?: Maybe<UserFilterInput>;
  orderBy?: Maybe<UserOrderBy>;
}>;

export type UserManyQuery = {
  userMany: {
    __typename: 'UserPage';
    total: number;
    items: Array<{
      __typename: 'User';
      id: string;
      fullName: string;
      createdAt?: Maybe<string>;
      status: UserStatus;
      organisation?: Maybe<{
        __typename: 'Organisation';
        id: string;
        name: string;
        district?: Maybe<string>;
        region?: Maybe<string>;
        city?: Maybe<string>;
      }>;
      position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
      requestedPosition: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
      requestedChanges?: Maybe<{
        __typename: 'UserRequestedChanges';
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        title?: Maybe<string>;
        phoneNumber?: Maybe<string>;
      }>;
    }>;
  };
};

export type PositionGroupAcceptOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type PositionGroupAcceptOptionsQuery = {
  positionGroups: Array<{ __typename: 'PositionGroupRadio'; label: string; value: string }>;
};

export type AcceptPositionChangeMutationVariables = Exact<{
  userId: Scalars['ID'];
  positionIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type AcceptPositionChangeMutation = {
  acceptPositionChange: {
    __typename: 'User';
    id: string;
    fullName: string;
    status: UserStatus;
    organisation?: Maybe<{ __typename: 'Organisation'; id: string; name: string }>;
    position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
    requestedPosition: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
  };
};

export type RejectPositionChangeMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type RejectPositionChangeMutation = {
  rejectPositionChange: {
    __typename: 'User';
    id: string;
    fullName: string;
    status: UserStatus;
    organisation?: Maybe<{ __typename: 'Organisation'; id: string; name: string }>;
    position: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
    requestedPosition: Array<{ __typename: 'UserPosition'; id: string; name: string }>;
  };
};

export type VideoSecretKeyQueryVariables = Exact<{
  path: Scalars['String'];
  userId: Scalars['ID'];
}>;

export type VideoSecretKeyQuery = {
  videoSecretKey: string;
  userVideoDataOne: { __typename: 'UserVideoData'; id: string; time: number };
};

export type UserStoreVideoDataMutationVariables = Exact<{
  userId: Scalars['ID'];
  path: Scalars['String'];
  time: Scalars['Int'];
}>;

export type UserStoreVideoDataMutation = { userStoreVideoData?: Maybe<void> };

export type UserOneGroupsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserOneGroupsQuery = {
  userOne: {
    __typename: 'User';
    id: string;
    organisation?: Maybe<{
      __typename: 'Organisation';
      id: string;
      group?: Maybe<{ __typename: 'UserGroup'; id: string; videoIds: Array<string> }>;
    }>;
    group?: Maybe<{ __typename: 'UserGroup'; id: string; videoIds: Array<string> }>;
    position: Array<{ __typename: 'UserPosition'; id: string; videoIds: Array<string> }>;
  };
};
